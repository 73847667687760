import { Box, Grid } from '@lojinha/design-system'
import styled from 'styled-components'
import MaskIllustration from '../../../assets/images/kit-presentation-mask.svg'

export const Container = styled(Box)`
  position: relative;

  ${({ theme }) => `
    ${theme.media.above('md')} {
      height: 672px;
    }

    ${theme.media.below('lg')}{
      padding-bottom: 168px;
    }
  `}
`

export const ContainerMask = styled(Grid)`
  position: absolute;
  top: ${({ theme }) => theme.space.x16};
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  ${({ theme }) => `

    ${theme.media.below('lg')}{
      align-items: flex-end;
      background: ${theme.colors.mangoExtraLight};
    }
  
    ${theme.media.above('md')}{
      padding-bottom: ${theme.space.x64};
    }
  `}
`

export const Mask = styled(Grid)`
  background-image: url(${MaskIllustration});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left bottom;
  width: 100%;

  ${({ theme }) => `
    background-color: ${theme.colors.mangoExtraLight};

    ${theme.media.above('md')}{
      border-bottom-right-radius: 276px;
      border-top-right-radius: 276px;
    }

    ${theme.media.below('lg')}{
      height: 322px;
      background-size: cover;
      background-position: left center;
    }
  `}
`

export const Content = styled(Grid)`
  ${({ theme }) => `
    padding-top: ${theme.space.x64};

    ${theme.media.below('lg')}{
      flex-direction: column;
      padding-top: ${theme.space.x48};
    }
  `}
`
