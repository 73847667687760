import React, { FC } from 'react'
import { CmsComponentContainerCustomHtml } from '@lojinha/palantir'
import { useCustomHtml } from './hook'

export const CustomHtml: FC<
  Omit<CmsComponentContainerCustomHtml, '_id' | 'id' | 'editor'>
> = ({ html, head, css, javascript }) => {
  useCustomHtml({ head, css, javascript })
  return <div dangerouslySetInnerHTML={{ __html: html || '' }} />
}
