import React, { FC } from 'react'

import { Stack } from '@lojinha/design-system'
import { CMSPageContent } from '@lojinha/cms-strapi/src/components/page-content'
import {
  CmsContainer,
  ContainerComponentsDynamicZoneUnion,
  Maybe,
} from '@lojinha/palantir'

import { BannerBreadcrumb } from '../../components/banner-breadcrumb'
import { MobileBreadcrumb } from '../../components/mobile-breadcrumb'
import { NavigationAnchors } from '../../components/navigation-anchors'
import {
  BannerComponent,
  DisplayComponent,
  DisplayItems,
  Page,
  PageComponent,
} from './page-content.types'
import { PageSeo } from './page-seo'
import { useAnchorControl } from './hook/use-anchor-control'
import { DisplayCtrl } from './components/display-ctrl'

interface IPageContentUI {
  loading: boolean
  page?: Page | null
  isHome: boolean
  fullPath: string
  onClickWillDeliver: () => void
  address: string | undefined
}

export const PageContentUI: FC<IPageContentUI> = ({
  page,
  isHome,
  fullPath,
}) => {
  const anchorableDisplays =
    (page?.components.nodes.filter(
      (
        container: PageComponent & {
          itemsOptimized?: Maybe<DisplayItems[]>
        }
      ) => container.__typename == 'Display' && container.itemsOptimized?.length
    ) as DisplayComponent[]) ?? []

  const CMSContainer =
    (page?.components.nodes.filter(
      container => container.__typename == 'CMSContainer'
    ) as CmsContainer[]) ?? []

  const anchorableComponents = anchorableDisplays.map(display => display.name)

  for (const container of CMSContainer) {
    anchorableComponents.push(
      ...(
        container.components?.filter(
          (
            component: ContainerComponentsDynamicZoneUnion & {
              isAnchorable?: Maybe<boolean>
              anchorText?: Maybe<string>
              title?: Maybe<string>
            }
          ) =>
            component.isAnchorable && (component.anchorText || component.title)
        ) ?? []
      ).map(
        (
          component: ContainerComponentsDynamicZoneUnion & {
            title?: Maybe<string>
          }
        ): string => {
          if (component.__typename === 'CMSComponentContainerText') {
            return component.anchorText || component.id
          }
          return component.title || component.id
        }
      )
    )
  }

  const { ref, active, setActive } = useAnchorControl({
    displayNames: anchorableComponents,
    skip: isHome,
  })

  return (
    <>
      <PageSeo
        seoTitle={page?.seo.title}
        seoDescription={page?.seo.description}
        url={fullPath}
      />

      <div ref={ref}>
        <Stack margin={['x24', 'x32', 'x40']} noZeroMargin>
          {page?.components.nodes.map((component, index) => {
            const componentTypename = component.__typename
            if (componentTypename === 'CMSContainer') {
              const cmsContainer = component as CmsContainer
              const componentKey = `${componentTypename}_${cmsContainer.id}`
              return (
                <CMSPageContent
                  key={componentKey}
                  container={cmsContainer}
                  anchoredComponents={{
                    ref,
                    active,
                    setActive,
                    anchors: anchorableComponents,
                  }}
                />
              )
            }

            if (componentTypename === 'Banner' && !isHome) {
              const bannerComponent = component as BannerComponent

              return (
                <>
                  <BannerBreadcrumb
                    component={bannerComponent}
                    fullPath={fullPath}
                    key={`banner_breadcrumb_${bannerComponent.url}`}
                  />
                  <NavigationAnchors
                    anchors={anchorableComponents}
                    active={active}
                    setActive={setActive}
                    key={`navigation_anchors_${ref.current?.id}`}
                  />
                  <MobileBreadcrumb
                    component={bannerComponent}
                    fullPath={fullPath}
                    key={`mobile_breadcrumb_${bannerComponent.url}`}
                  />
                </>
              )
            }

            if (componentTypename === 'Display') {
              const displayComponent = component as DisplayComponent
              const componentKey = `${componentTypename}_${displayComponent.id}`
              return (
                <DisplayCtrl
                  key={componentKey}
                  displayComponent={component as DisplayComponent}
                  componentTypename={componentTypename}
                  index={index}
                  isHome={isHome}
                />
              )
            }

            return null
          })}
        </Stack>
      </div>
    </>
  )
}
