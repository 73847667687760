import generatedBreadcrumbs from '@lojinha/vitrine/generated/breadcrumbs/breadcrumbs.json'
import { CrumbProps, GeneratedCrumb } from '../types'

const getCrumbByRoute = (route: string): GeneratedCrumb | undefined => {
  const formattedJson = generatedBreadcrumbs as GeneratedCrumb[]

  return formattedJson.find(item => item.route === route)
}

export const useBreadCrumbs = (route: string): CrumbProps[] => {
  const item = getCrumbByRoute(route)

  if (!item) {
    return []
  }

  const parentCrumbs = item.crumbs.sort(item => item.level)
  const currentCrumb = { ...item, isCurrent: true }

  return [...parentCrumbs, currentCrumb]
}
