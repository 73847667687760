import React, { createRef, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  Center,
  Grid,
  Stack,
  tabNavigationStyle,
  Text,
  useWindowProperties,
} from '@lojinha/design-system'
import { normalizeHtmlId } from '@lojinha/helpers'

const StickyContainer = styled(Stack)<{ isSticky: boolean }>(
  ({ theme, isSticky }) => `
    position: -webkit-sticky;
    position: sticky;
    top: ${theme.space.x96};
    height: ${theme.space.x64};
    background: ${theme.colors.white};
    margin: 0 !important;
    box-shadow: ${isSticky ? '0px 12px 12px rgba(0, 0, 0, 0.08)' : 'none'};
    z-index: 2;

    ${theme.media.below('lg')} {
     top: ${theme.space.x56};
    }
  `
)

const Container = styled.div<{ isMobile?: boolean }>(
  ({ theme, isMobile }) => `
  display: flex;
  align-items: center;
  padding-left: ${isMobile ? theme.space.x16 : theme.space.x0};
  padding-right: ${theme.space.x16};
  justify-content: flex-start;
  gap: ${theme.space.x8};
  -webkit-box-pack: start;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    display: none;
  }
`
)

const Anchor = styled.a<{ isActive: boolean }>(
  ({ theme, isActive }) => `
    min-width: fit-content;
    border-radius: ${theme.space.x48};
    background: ${isActive ? theme.colors.success : theme.colors.white};
    color: ${isActive ? theme.colors.white : theme.colors.black};
    padding: ${theme.space.x12} ${theme.space.x24};
    text-decoration: none;
    border: 1px solid ${theme.colors.gray200};
    margin-top: ${theme.space.x12};
    margin-bottom: ${theme.space.x12};
    cursor: pointer;
`,
  tabNavigationStyle
)

const StickyPinned = styled.div`
  visibility: hidden;
  min-height: 1px;
  min-width: 1px;
`

export const NavigationAnchors = ({
  anchors,
  active,
  setActive,
}: {
  anchors: string[]
  active: string
  setActive: (s: string) => void
}) => {
  const [isSticky, setIsSticky] = useState(false)
  const stickyRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const { isMobile } = useWindowProperties()

  const anchorRefs = anchors.reduce((refs: any, anchor) => {
    return {
      ...refs,
      [normalizeHtmlId(anchor)]: createRef<HTMLAnchorElement>(),
    }
  }, {})

  useEffect(() => {
    const cachedRef = stickyRef?.current
    const observer = new IntersectionObserver(
      entries => {
        document.querySelector('header')!.style.boxShadow = 'none'
        setIsSticky(entries[0].intersectionRatio < 1)
      },
      {
        threshold: [0, 1],
      }
    )

    cachedRef && observer.observe(cachedRef)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const calculateNewHorizontalScrollPosition = () => {
    const activeArchor = anchorRefs[active]?.current
    const container = containerRef?.current
    const containerWidth = container?.offsetWidth ?? 0
    const activeArchorWidth = activeArchor?.offsetWidth ?? 0
    const centerPosition = containerWidth / 2 - activeArchorWidth / 2
    return (
      (container?.scrollLeft ?? 0) -
      (centerPosition - activeArchor?.getBoundingClientRect().left ?? 0)
    )
  }

  const repositionHorizontalScroll = () => {
    containerRef.current?.scrollTo({
      top: 0,
      left: calculateNewHorizontalScrollPosition(),
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    if (active) {
      repositionHorizontalScroll()
    }
  }, [active])

  if (anchors.length <= 1) return null

  return (
    <>
      <StickyPinned ref={stickyRef} css={{ margin: 0 }} />
      <StickyContainer isSticky={isSticky}>
        <Center>
          <Grid gridTemplateColumns={['repeat(24, 1fr)', 'repeat(12, 1fr)']}>
            <Grid gridColumn={['1 / 26', '2 / 12']}>
              <Container ref={containerRef} isMobile={isMobile}>
                {anchors.map((anchor, i) => {
                  const anchorId: string = normalizeHtmlId(anchor)
                  const isActive = anchorId === active

                  return (
                    <Anchor
                      key={`${anchor}-${i}`}
                      isActive={isActive}
                      onClick={() => setActive(anchorId)}
                      ref={anchorRefs[anchorId]}
                    >
                      <Text
                        isBold={isActive}
                        textTransform="capitalize"
                        textSize="x14"
                      >
                        {anchor}
                      </Text>
                    </Anchor>
                  )
                })}
              </Container>
            </Grid>
          </Grid>
        </Center>
      </StickyContainer>
    </>
  )
}
