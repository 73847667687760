import React, { FC } from 'react'
import styled, { FlattenSimpleInterpolation } from 'styled-components'
import { useWindowProperties } from '@lojinha/design-system'
import { CmsComponentContainerText } from '@lojinha/palantir/src/palantirTypes'
import { normalizeHtmlId } from '@lojinha/helpers'

type CustomTextProps = Pick<
  CmsComponentContainerText,
  'customText' | 'anchorText' | 'id'
> & {
  customStyles?: FlattenSimpleInterpolation
}

const CMSCustomText = styled.div<{
  isMobile: boolean | undefined
  customStyles?: FlattenSimpleInterpolation
}>(
  ({ isMobile, customStyles }) => `
  font-size: ${isMobile ? '14' : '16'}px;
  line-height: ${isMobile ? '18' : '24'}px;
  padding: 0px;

  h1, h2, h3, h4 {
    font-family: Amsi Pro Normal;
    font-style: normal;
    font-weight: 900;
    margin-top: ${isMobile ? '48' : '80'}px;
    margin-bottom: ${isMobile ? '24' : '40'}px;
  }

  h1 {
    font-size: ${isMobile ? '32' : '48'}px;
    line-height: ${isMobile ? '40' : '56'}px;
    text-transform: uppercase;
  }

  h2 {
    font-size: ${isMobile ? '24' : '32'}px;
    line-height: ${isMobile ? '32' : '40'}px;
    text-transform: uppercase;
  }

  h3 {
    font-size: ${isMobile ? '16' : '24'}px;
    line-height: ${isMobile ? '24' : '32'}px;
    text-transform: uppercase;
  }

  h4 {
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 20px;
    margin-right: ${isMobile ? '0px' : '110px'};
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style-type: inherit;
  }

  ol, ul {
    list-style-position: outside;
    margin-left: 15px;
    margin-right: ${isMobile ? '0px' : '110px'};
  }

  ol ol {
    counter-reset: list;
  }

  ol ol li {
    padding-left: 4px;
    counter-increment: list;
  }

  ol ol li::marker {
    content: counter(list, upper-alpha) ")";
  }

  li {
    padding-left: ${isMobile ? '14' : '40'}px;
    margin: ${isMobile ? '24' : '40'}px 0;
  }

  ol > li::marker {
    font-weight: bold;
  }

  blockquote {
    border-left: 2px solid #ECECEC;
    padding: 16px ${isMobile ? '24' : '48'}px;
    margin-right: ${isMobile ? '0px' : '110px'};
    padding-right: 0;
  }

  blockquote p {
    margin-right: 0px;
  }

  h1:last-child,
  h2:last-child,
  h3:last-child,
  h4:last-child,
  ol:last-child li:last-child,
  ul:last-child li:last-child,
  p:last-child {
    margin-bottom: 0;
  }

  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  ol:first-child li:first-child,
  ul:first-child li:first-child,
  p:first-child {
    margin-top: 0;
  }

  a { color: inherit; }

  b { font-weight: 600 }

  ${customStyles || ''}
`
)

export const CustomText: FC<CustomTextProps> = ({
  id,
  anchorText,
  customText,
  customStyles,
}) => {
  const { isMobile } = useWindowProperties()
  const elementId = normalizeHtmlId(anchorText || id)

  return (
    <CMSCustomText
      id={elementId}
      isMobile={isMobile}
      customStyles={customStyles}
      dangerouslySetInnerHTML={{ __html: customText }}
    ></CMSCustomText>
  )
}
