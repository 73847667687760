import { BadgeColorPalette } from '@lojinha/design-system'
import { ItemTag } from '@lojinha/palantir'
import { DisplayItems } from '@lojinha/vitrine/src/modules/page-content/page-content.types'

export function applyFreeShippingTagToItem(
  item: DisplayItems,
  freeShippingThreshold: number
): DisplayItems {
  if ((item.price || 0) < freeShippingThreshold) {
    return item
  }

  const newItemTagsNodes: ItemTag[] = [
    {
      __typename: 'ItemTag',
      colorPalette: BadgeColorPalette.FreeShipping as any,
      content: 'FREE_SHIPPING' as any,
    },
    ...item.itemTags.nodes,
  ]

  return {
    ...item,
    itemTags: {
      __typename: 'ItemTagConnection',
      nodes: newItemTagsNodes,
      totalCount: newItemTagsNodes.length,
    },
  }
}
