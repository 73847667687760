import { Center, Grid, Stack, Text } from '@lojinha/design-system'
import { theme } from '@lojinha/design-system/src/theme'
import { testId } from '@lojinha/helpers'
import Image, { ImageLoaderProps } from 'next/image'
import React, { FC } from 'react'
import { BannerComponent } from '@lojinha/vitrine/src/modules/page-content/page-content.types'
import { Breadcrumb } from '@lojinha/vitrine/src/components/breadcrumb'
import { ContentWrapper, ImageWrapper, Wrapper } from './styles'

export const HeaderBanner: FC<Omit<BannerComponent, '__typename'>> = ({
  url,
  description,
  title,
  crumbs,
}) => {
  const myImageLoader = ({ src }: ImageLoaderProps) => {
    return `${src}?auto=compress,format`
  }

  if (!url) {
    return null
  }

  return (
    <Wrapper display={['none', 'none', 'flex']}>
      <ImageWrapper>
        <Image
          src={url}
          alt={title ?? ''}
          aria-hidden
          priority
          layout="fill"
          loader={myImageLoader}
        />
      </ImageWrapper>
      <Center>
        <ContentWrapper gridTemplateColumns="repeat(12, 1fr)">
          <Grid gridColumn="2 / 5">
            <Stack layout="vertical" margin="x0">
              <Grid gridTemplateColumns="repeat(3, 1fr)">
                <Grid gridColumn="1 / 3">
                  <Text
                    textSize="x4"
                    color="white"
                    isBrand
                    textTransform="uppercase"
                    as="h1"
                    {...testId('banner-title')}
                  >
                    {title}
                  </Text>
                </Grid>
              </Grid>
              <Text
                textSize="x2"
                color="white"
                {...testId('banner-description')}
              >
                {description}
              </Text>
              <Center
                htmlAttrs={{
                  style: {
                    marginBottom: `-${theme.space.x40}`,
                    marginTop: theme.space.x48,
                  },
                }}
              >
                <Grid gridTemplateColumns="repeat(12, 1fr)">
                  <Grid gridColumn="1 / 12" gridRowGap="x0" display="block">
                    <Breadcrumb crumbs={crumbs} variant="light" />
                  </Grid>
                </Grid>
              </Center>
            </Stack>
          </Grid>
        </ContentWrapper>
      </Center>
    </Wrapper>
  )
}
