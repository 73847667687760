import { CmsComponentContainerVideo } from '@lojinha/palantir/src/palantirTypes'
import React, { FC } from 'react'
import { VideoPlayerIframe, VideoWrapper } from './styles'

interface VideoPlayerProps
  extends Omit<CmsComponentContainerVideo, '_id' | 'id'> {
  height?: string | number
}

export const VideoPlayer: FC<VideoPlayerProps> = ({ url, title, height }) => {
  return (
    <VideoWrapper>
      <VideoPlayerIframe
        src={`${url}?rel=0&modestbranding=1&autohide=1&showinfo=0`}
        title={title ?? ''}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        frameBorder={0}
        height={height}
      />
    </VideoWrapper>
  )
}
