import React, { FC } from 'react'
import { Center, Grid, Stack, Skeleton } from '@lojinha/design-system'
import styled from 'styled-components'

const DesktopHighlights = styled.div`
  height: 109px;
  min-width: calc((100% - 80px) / 6);
  ${({ theme }) => theme.media.below('sm')} {
    display: none;
  }
`

const MobileHighlights = styled.div`
  min-width: calc((100% - 16px) / 3);
  height: 72px;
  ${({ theme }) => theme.media.above('sm')} {
    display: none;
  }
`

const LoadingText = styled.div`
  width: ${({ theme }) => theme.space.x128};
  height: ${({ theme }) => theme.space.x16};
  ${({ theme }) => theme.media.below('sm')} {
    margin-left: ${({ theme }) => theme.space.x16};
  }
`

export const HighlightsLoading: FC = () => {
  return (
    <Center>
      <Grid gridTemplateColumns={['repeat(24, 1fr)', 'repeat(12, 1fr)']}>
        <Grid gridColumn={['1 / 22', '2 / 12']}>
          <Stack margin={['x16', 'x24']}>
            <LoadingText>
              <Skeleton />
            </LoadingText>

            <Stack layout="horizontal">
              {[...Array(6)].map((_, i) => (
                <DesktopHighlights key={`highlights-desk-${i}`}>
                  <Skeleton htmlAttrs={{ style: { borderRadius: '8px' } }} />
                </DesktopHighlights>
              ))}
              {[...Array(3)].map((_, i) => (
                <MobileHighlights key={`highlights-mobile-${i}`}>
                  <Skeleton htmlAttrs={{ style: { borderRadius: '8px' } }} />
                </MobileHighlights>
              ))}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Center>
  )
}
