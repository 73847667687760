import {
  Center,
  Grid,
  Stack,
  Text,
  useWindowProperties,
} from '@lojinha/design-system'
import { theme } from '@lojinha/design-system/src/theme'
import { imgix, testId, getBannerImageByUrl } from '@lojinha/helpers'
import Image from 'next/image'
import React, { FC } from 'react'
import { BannerComponent } from '../../modules/page-content/page-content.types'
import { Breadcrumb } from '../breadcrumb'
import { ContentWrapper, ImageWrapper, PureImage, Wrapper } from './styles'

export const Banner: FC<BannerComponent> = ({
  url,
  description,
  title,
  crumbs,
}) => {
  const { isDesktop = false } = useWindowProperties()

  if (!url) {
    return null
  }

  const { lowQualityUrl, optimizedUrl } = getBannerImageByUrl(url, isDesktop)

  return (
    <Wrapper display={['none', 'none', 'flex']}>
      <ImageWrapper>
        <PureImage src={lowQualityUrl} alt="" aria-hidden loading="lazy" />
        <Image
          src={optimizedUrl.replace(imgix.DOMAIN, '/')}
          alt=""
          aria-hidden
          layout="fill"
        />
      </ImageWrapper>
      <Center>
        <ContentWrapper gridTemplateColumns="repeat(12, 1fr)">
          <Grid gridColumn="2 / 5">
            <Stack layout="vertical" margin="x0">
              <Grid gridTemplateColumns="repeat(3, 1fr)">
                <Grid gridColumn="1 / 3">
                  <Text
                    textSize="x4"
                    color="white"
                    isBrand
                    textTransform="uppercase"
                    as="h1"
                    {...testId('banner-title')}
                  >
                    {title}
                  </Text>
                </Grid>
              </Grid>
              <Text
                textSize="x2"
                color="white"
                {...testId('banner-description')}
              >
                {description}
              </Text>
              <Center
                htmlAttrs={{
                  style: {
                    marginBottom: `-${theme.space.x40}`,
                    marginTop: theme.space.x48,
                  },
                }}
              >
                <Grid gridTemplateColumns="repeat(12, 1fr)">
                  <Grid gridColumn="1 / 12" gridRowGap="x0" display="block">
                    <Breadcrumb crumbs={crumbs} variant="light" />
                  </Grid>
                </Grid>
              </Center>
            </Stack>
          </Grid>
        </ContentWrapper>
      </Center>
    </Wrapper>
  )
}
