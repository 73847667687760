import { MarketableKind } from '@lojinha/palantir'
import { captureException } from '@lojinha/sentry'
import React, { FC, useEffect } from 'react'
import { sortedItemsByAvailability } from '@lojinha/vitrine/src/helpers/products'
import { CarouselDisplay } from '../../components/carousel-display'
import { ListDisplay } from '../../components/list-display'
import { t } from '../../dictionary'
import { DisplayComponent } from '../../modules/page-content/page-content.types'
import { KitPresentation } from './kit-presentation'
import {
  onArrowSlideClick,
  onItemClick,
  onRenderItem,
  onRenderList,
  onSeeAllClick,
} from './logic'
import { OriginList } from './types'

export const Display: FC<DisplayComponent> = ({
  id,
  itemsOptimized: items,
  name,
  slug,
  kind,
  isHome,
  isSubcategory,
  isFirstDisplay,
}) => {
  useEffect(
    () => onRenderList({ id, name, items }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  if (!items.length) return null

  const isKit = kind === MarketableKind.Kit
  const kindMealOrProduct =
    kind === MarketableKind.Product || kind === MarketableKind.Meal

  if (isKit && isHome) {
    return (
      <KitPresentation
        title={t.kitTitle}
        description={t.kitDescription}
        items={sortedItemsByAvailability(items)}
        slug={slug}
        onItemClick={onItemClick}
        onRenderItem={onRenderItem}
        originList={OriginList.LIST}
      />
    )
  }

  if (isSubcategory || isKit) {
    return (
      <ListDisplay
        id={id}
        items={sortedItemsByAvailability(items)}
        name={name}
        kind={kind}
        onRenderItem={onRenderItem}
        onItemClick={onItemClick}
        originList={OriginList.CATEGORY}
        slug={slug}
        isFirstDisplay={isFirstDisplay}
      />
    )
  }

  if (kindMealOrProduct) {
    return (
      <CarouselDisplay
        id={id}
        showLastCard={items.length === 7}
        items={sortedItemsByAvailability(items)}
        name={name}
        slug={slug}
        onArrowSlideClick={onArrowSlideClick}
        onSeeAllClick={onSeeAllClick}
        onRenderItem={onRenderItem}
        onItemClick={onItemClick}
        originList={OriginList.CAROUSEL}
        kind={kind}
      />
    )
  }

  captureException(new Error('Error: display without kind'))
  return null
}
