import {
  CmsContainer,
  IcmsEnumContainerSize,
  ContainerComponentsDynamicZoneUnion,
  CmsComponentContainerBanner,
  CmsComponentContainerImage,
  CmsComponentContainerCallToAction,
  CmsComponentContainerDeliveryAreaCard,
  CmsComponentContainerHeader,
  CmsComponentContainerProducerAreaCards,
  CmsComponentContainerProducerCard,
  CmsComponentContainerQuote,
  CmsComponentContainerText,
  CmsComponentContainerVideo,
  CmsComponentDeliveryAreaCards,
  CmsComponentHighlightStoryHighlightStories,
  CmsComponentContainerHighlightStory,
  CmsComponentContainerDisplay,
  CmsComponentContainerCustomHtml,
  CmsComponentContainerButton,
  CmsComponentContainerAccordion,
  CmsComponentContainerBannerBreadcrumb,
} from '@lojinha/palantir/src/palantirTypes'
import { Header } from './components/header'
import { ProducerCard, ProducerCardProps } from './components/producer-card'
import { Image } from './components/image'
import { CustomText } from './components/custom-text'
import { DeliveryAreaCard } from './components/delivery-area-card'
import { DeliveryAreaCards } from './components/delivery-area-cards'
import { ProducerAreaCards } from './components/producer-area-cards'
import { VideoPlayer } from './components/video-player'
import { CallToAction } from './components/call-to-action'
import { Quote } from './components/quote'
import { Banner } from './components/banner'
import { HighlightStories } from './components/highlight-stories'
import {
  HighlightStory,
  HighlightStoryProps,
} from './components/highlight-story'
import { Display } from './components/display'
import { CustomHtml } from './components/custom-html'
import { Button } from './components/button'
import { AccordionList } from './components/accordion-list'
import { BannerBreadcrumb } from './components/banner-breadcrumb'
import { NavigationAnchors } from './components/navigation-anchors'
import { IAnchorControl } from './components/navigation-anchors/types'

const mediaSize = {
  [IcmsEnumContainerSize.Fullscreen]: 674,
  [IcmsEnumContainerSize.Fullpage]: 674,
  [IcmsEnumContainerSize.Content]: 332,
}

export const CMSComponents = {
  CMSComponentContainerBanner: (props: ContainerComponentsDynamicZoneUnion) => {
    const {
      description,
      imageDesktop,
      imageMobile,
      title,
      redirectUrl,
    } = props as CmsComponentContainerBanner

    return Banner({
      description,
      imageDesktop: imageDesktop?.url ?? '',
      imageMobile: imageMobile?.url ?? '',
      title: title,
      redirectUrl: redirectUrl,
    })
  },
  CMSComponentContainerText: (props: ContainerComponentsDynamicZoneUnion) => {
    const { id, customText, anchorText } = props as CmsComponentContainerText
    return CustomText({ id, customText, anchorText })
  },
  CMSComponentContainerImage: (props: ContainerComponentsDynamicZoneUnion) => {
    const {
      image,
      title,
      isVertical,
      borderRadius,
      description,
    } = props as CmsComponentContainerImage
    return Image({
      title,
      isVertical,
      image: image?.url ?? '',
      borderRadius,
      description: description,
    })
  },
  CMSComponentContainerHeader: (props: ContainerComponentsDynamicZoneUnion) => {
    const { text, title, color } = props as CmsComponentContainerHeader

    return Header({
      text,
      title,
      color: color || undefined,
    })
  },
  CMSComponentContainerProducerCard: (
    props: ContainerComponentsDynamicZoneUnion
  ) => {
    const {
      image,
      name,
      occupation,
      redirectUrl,
    } = props as CmsComponentContainerProducerCard

    return ProducerCard({
      image: { url: image?.url },
      name,
      occupation,
      redirectUrl,
    })
  },
  CMSComponentContainerProducerAreaCards: (
    props: ContainerComponentsDynamicZoneUnion
  ) => {
    const { cards } = props as CmsComponentContainerProducerAreaCards

    return ProducerAreaCards({ cards: cards as ProducerCardProps[] })
  },
  CMSComponentDeliveryAreaCards: (
    props: ContainerComponentsDynamicZoneUnion
  ) => {
    const { cards } = props as CmsComponentDeliveryAreaCards

    return DeliveryAreaCards({ cards })
  },
  CMSComponentContainerDeliveryAreaCard: (
    props: ContainerComponentsDynamicZoneUnion
  ) => {
    const {
      state,
      mainCity,
      deliveryMapLink,
      takeoffMapLink,
      minorCities,
    } = props as CmsComponentContainerDeliveryAreaCard

    return DeliveryAreaCard({
      state,
      mainCity,
      deliveryMapLink,
      takeoffMapLink,
      minorCities,
    })
  },
  CMSComponentContainerVideo: (
    component: ContainerComponentsDynamicZoneUnion,
    container: CmsContainer
  ) => {
    const { title, url, description } = component as CmsComponentContainerVideo
    const { size } = container

    return VideoPlayer({ title, url, height: mediaSize[size], description })
  },
  CMSComponentContainerCallToAction: (
    props: ContainerComponentsDynamicZoneUnion
  ) => {
    const {
      text,
      title,
      color,
      image,
      link,
      buttonText,
    } = props as CmsComponentContainerCallToAction

    return CallToAction({
      text,
      title,
      image,
      link,
      buttonText,
      color,
    })
  },
  CMSComponentContainerQuote: (props: ContainerComponentsDynamicZoneUnion) => {
    const { quote, author } = props as CmsComponentContainerQuote

    return Quote({ quote, author })
  },
  CMSComponentHighlightStoryHighlightStories: (
    props: ContainerComponentsDynamicZoneUnion
  ) => {
    const { highlights } = props as CmsComponentHighlightStoryHighlightStories

    return HighlightStories({
      highlights: highlights as HighlightStoryProps[],
    })
  },
  CMSComponentContainerHighlightStory: (
    props: ContainerComponentsDynamicZoneUnion
  ) => {
    const {
      title,
      destinationUrl,
      image,
    } = props as CmsComponentContainerHighlightStory

    return HighlightStory({
      title,
      destinationUrl,
      image: { url: image?.url },
    })
  },
  CMSComponentContainerDisplay: (
    props: ContainerComponentsDynamicZoneUnion
  ) => {
    const {
      id,
      title,
      redirectUrl,
      home,
      subcategory,
      cardSize,
      items,
    } = props as CmsComponentContainerDisplay
    return Display({
      id,
      title,
      redirectUrl,
      home,
      subcategory,
      cardSize,
      items,
    })
  },
  CMSComponentContainerCustomHtml: (
    props: ContainerComponentsDynamicZoneUnion
  ) => {
    const {
      html,
      head,
      css,
      javascript,
    } = props as CmsComponentContainerCustomHtml
    return CustomHtml({ html, head, css, javascript })
  },
  CMSComponentContainerButton: (props: ContainerComponentsDynamicZoneUnion) => {
    const {
      text,
      redirectUrl,
      size,
      type,
      block,
      newTab,
    } = props as CmsComponentContainerButton
    return Button({ text, redirectUrl, size, type, block, newTab })
  },
  CMSComponentContainerAccordion: (
    props: ContainerComponentsDynamicZoneUnion
  ) => {
    const {
      id,
      title,
      description,
      accordionItem,
    } = props as CmsComponentContainerAccordion
    return AccordionList({ id, title, description, accordionItem })
  },
  CMSComponentContainerBannerBreadcrumb: (
    props: ContainerComponentsDynamicZoneUnion
  ) => {
    const {
      id,
      image,
      title,
      description,
    } = props as CmsComponentContainerBannerBreadcrumb
    return BannerBreadcrumb({ id, image, title, description })
  },
  CMSComponentContainerNavigationAnchors: (
    _props: ContainerComponentsDynamicZoneUnion,
    _container: CmsContainer,
    achoredComponents: IAnchorControl
  ) => {
    return NavigationAnchors(achoredComponents)
  },
}
