import React, { FC } from 'react'
import { Grid, Box } from '@lojinha/design-system'
import { testId } from '@lojinha/helpers/src/test-id'
import { CmsComponentDeliveryAreaCards } from '@lojinha/palantir/src/palantirTypes'
import { DeliveryAreaCard } from '../delivery-area-card/index'

type DeliveryAreaCardProps = Omit<CmsComponentDeliveryAreaCards, '_id' | 'id'>

export const DeliveryAreaCards: FC<DeliveryAreaCardProps> = ({ cards }) => {
  return (
    <Grid gridColumn={['1 / 24', '1 / 12']}>
      <Grid
        gridTemplateColumns={[
          'repeat(1, 1fr)',
          'repeat(3, 1fr)',
          'repeat(3, 1fr)',
        ]}
        gridGap="x16"
      >
        {cards?.map(card => {
          return (
            card && (
              <Box key={`card-${card.id}`} {...testId('delivery-area-card')}>
                <DeliveryAreaCard
                  state={card.state}
                  mainCity={card.mainCity}
                  minorCities={card?.minorCities}
                  deliveryMapLink={card.deliveryMapLink}
                  takeoffMapLink={card?.takeoffMapLink}
                />
              </Box>
            )
          )
        })}
      </Grid>
    </Grid>
  )
}
