import React, { FC } from 'react'

import { Router } from '../../../router'
import { LoadingComponent } from './components/loading'
import { usePageContent } from './hook'
import { NoPageComponent } from './no-page.ui'
import { PageContentUI } from './page-content.ui'

const onEmptyStateClick = () => {
  Router.push('/')
}

export const PageContent: FC<{
  pageUrl: string | string[]
}> = ({ pageUrl }) => {
  const {
    loading,
    page,
    fullPath,
    showEmptyState,
    onClickWillDeliver,
    address,
    isHome,
  } = usePageContent({
    pageUrl,
  })
  if (showEmptyState) {
    return <NoPageComponent onButtonClick={onEmptyStateClick} />
  }
  if (page) {
    return (
      <PageContentUI
        loading={loading}
        page={page}
        isHome={isHome}
        fullPath={fullPath}
        onClickWillDeliver={onClickWillDeliver}
        address={address}
      />
    )
  }
  if (loading) {
    return <LoadingComponent isHome={isHome} />
  }
  return <NoPageComponent onButtonClick={onEmptyStateClick} />
}
