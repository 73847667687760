import React, { FC } from 'react'
import { Box, Grid } from '@lojinha/design-system'
import { testId } from '@lojinha/helpers'
import { CmsComponentContainerProducerAreaCards } from '@lojinha/palantir/src/palantirTypes'
import { ProducerCard, ProducerCardProps } from '../producer-card/index'

interface ProducerAreaCardProps
  extends Omit<CmsComponentContainerProducerAreaCards, '_id' | 'id' | 'cards'> {
  cards?: ProducerCardProps[]
}

export const ProducerAreaCards: FC<ProducerAreaCardProps> = ({ cards }) => {
  const cols = (cards?.length ?? 0) + 1
  return (
    <Grid
      gridColumn={['1 / 24', '1 / 12']}
      htmlAttrs={{
        style: {
          margin: 'auto',
        },
      }}
    >
      <Grid
        gridTemplateColumns={[`repeat(${cols}, 1fr)`, 'repeat(3, 1fr)']}
        gridGap="x16"
      >
        {cards?.map(
          card =>
            card && (
              <Box key={`card-${card.name}`} {...testId('producer-area-card')}>
                <ProducerCard {...card} image={{ url: card.image?.url }} />
              </Box>
            )
        )}
      </Grid>
    </Grid>
  )
}
