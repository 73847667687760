import styled from 'styled-components'

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  margin-inline: auto;
  & img {
    width: 100%;
    object-fit: cover;
    vertical-align: middle;
  }
`

export const PureImage = styled.img`
  position: absolute;
  width: auto;
  object-fit: cover;
`
