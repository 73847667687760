import React, { FC } from 'react'

import { Box, Stack, useWindowProperties } from '@lojinha/design-system'
import { CmsComponentContainerBannerBreadcrumb } from '@lojinha/palantir'
import { testId } from '@lojinha/helpers'

import { HeaderBanner } from '../header-banner'

export const BannerBreadcrumb: FC<CmsComponentContainerBannerBreadcrumb> = ({
  title,
  description,
  image,
}) => {
  const { isMobile } = useWindowProperties()

  if (!image?.url) return null

  const imageUrl = image.url

  return (
    <>
      {/* Desktop Banner and Breadcrumb */}
      <Box
        display={['none', 'none', 'flex']}
        aria-hidden={isMobile}
        {...testId('banner-breadcrumb-test-desktop')}
      >
        <Stack
          margin="x32"
          htmlAttrs={{ style: { width: '100%' } }}
          noZeroMargin
        >
          <HeaderBanner
            title={title}
            description={description}
            url={imageUrl}
          />
        </Stack>
      </Box>
    </>
  )
}
