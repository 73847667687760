import React from 'react'
import { Box, Button, Center, Grid, Text, theme } from '@lojinha/design-system'
import { sanitizeImage } from '@lojinha/helpers'
import { ProductCardKit } from '../../../components/product-card/kit'
import { t } from '../../../dictionary'
import { getSlicedItems } from '../logic'
import { DisplayKitPresentationProps } from '../types'
import { Link } from '../../../components/link'
import { Container, ContainerMask, Content, Mask } from './styles'
import { useLojinhaContext } from '@lojinha/context'

export const KitPresentation = ({
  title,
  description,
  slug,
  originList,
  onLinkClick,
  onItemClick,
  onRenderItem,
  items,
}: DisplayKitPresentationProps) => {
  const currentItems = getSlicedItems(items)
  const { user } = useLojinhaContext()

  return (
    <Container>
      <Center
        htmlAttrs={{
          style: {
            height: '100%',
            padding: `${theme.space.x16} ${theme.space.x16} 0`,
          },
        }}
      >
        <Content
          gridTemplateColumns={'repeat(12, 1fr)'}
          display={['flex', 'flex', 'grid']}
        >
          <Grid
            gridColumn="3 / 6"
            display={['flex', 'flex', 'block']}
            htmlAttrs={{
              style: {
                justifyContent: 'space-between',
                paddingBottom: theme.space.x24,
              },
            }}
          >
            <Text
              isBrand
              isBold
              color="blueberryDarkSupport"
              textTransform="uppercase"
              htmlAttrs={{ style: { width: '227px' } }}
            >
              {title}
            </Text>
            <Box
              maxWidth="348px"
              display={['none', 'none', 'block']}
              paddingTop="x8"
              paddingBottom="x16"
            >
              <Text color="blueberryDarkSupport"> {description}</Text>
            </Box>
            {slug && (
              <Box width="96px">
                <Link href={slug}>
                  <Button
                    ariaLabel={`${t.seeAll} ${t.kits}`}
                    color="success"
                    onClick={() => {
                      if (!onLinkClick) return
                      onLinkClick()
                    }}
                    size="small"
                    isBlock
                    htmlAttrs={{
                      tabIndex: -1,
                    }}
                  >
                    {t.seeAll}
                  </Button>
                </Link>
              </Box>
            )}
          </Grid>
          <Grid gridColumn="7 / 12" gridGap="x16">
            {currentItems.map((item, index) => (
              <ProductCardKit
                displayName={title}
                id={item.id}
                kind={item.kind}
                classification={item.classification}
                key={item.id}
                title={item.name}
                description={item.description}
                badges={item.itemTags.nodes}
                isAvailable={item.isAvailable}
                imageUrl={sanitizeImage({
                  imageUrl: item.imageUrl,
                  size: 'medium',
                })}
                price={item.price ?? 0}
                priceFrom={item.priceFrom ?? 0}
                quantity={0}
                sku={item.sku}
                imageAriaLabel={item.name}
                pricePerMeal={item.pricePerMeal ?? 0}
                eventList={originList}
                positionInList={index}
                onItemClick={() =>
                  onItemClick &&
                  onItemClick({
                    sku: item.sku,
                    name: item.name,
                    positionInList: index,
                    price: item.price ?? 0,
                    originList: originList,
                    isAvailable: item.isAvailable,
                    user,
                  })
                }
                onRenderItem={() =>
                  onRenderItem &&
                  onRenderItem({
                    id: item.id,
                    name: item.name,
                    positionInList: index,
                    price: item.price ?? 0,
                    listName: originList,
                    isAvailable: item.isAvailable,
                  })
                }
                slug={item.seo.url}
                firstOfferReplacement={item.firstOfferReplacement}
              />
            ))}
          </Grid>
        </Content>
      </Center>
      <ContainerMask
        gridTemplateColumns="repeat(12, 1fr)"
        display={['flex', 'flex', 'grid']}
      >
        <Mask gridColumn="1 / 9"></Mask>
      </ContainerMask>
    </Container>
  )
}
