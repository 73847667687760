import { Box, Grid } from '@lojinha/design-system'
import styled from 'styled-components'

export const Wrapper = styled(Box)`
  position: relative;
  width: 100%;
  height: 296px;
  align-items: center;
`

export const ContentWrapper = styled(Grid)`
  position: relative;
`

export const ImageWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;

  & img {
    object-fit: cover;
    vertical-align: middle;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      101.17deg,
      #000000 3.16%,
      rgba(0, 0, 0, 0.484375) 36.38%,
      rgba(0, 0, 0, 0) 51.54%
    );
  }
`

export const PureImage = styled.img`
  position: absolute;
  width: auto;
  object-fit: cover;
`
