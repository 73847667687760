import React from 'react'
import styled from 'styled-components'
import { Skeleton } from '@lojinha/design-system'
import { testId } from '@lojinha/helpers'

const LoadingBanner = styled.div<{ isDish?: boolean }>(
  ({ theme }) => `
    width: 100%;
    height: 336px;
    padding-bottom: ${theme.space.x40};
    
    ${theme.media.below('md')} {
      display: none;
    }
  `
)

export const BannerLoading = () => {
  return (
    <LoadingBanner {...testId('banner-loading')}>
      <Skeleton />
    </LoadingBanner>
  )
}
