import { Box, Stack } from '@lojinha/design-system'
import React from 'react'
import { HighlightsLoading } from '@lojinha/cms-strapi/src/components/highlight-story/loading'
import { BannerLoading } from '@lojinha/cms-strapi/src/components/header-banner/loading.ui'
import { BannerLandingLoading } from '../../../../components/banner-landing/loading.ui'
import { CarouselDisplayLoading } from '../../../../components/carousel-display/loading.ui'

export const LoadingComponent = ({ isHome = false }: { isHome?: boolean }) => (
  <Box marginTop={['x56', 'x96']}>
    <Stack margin={['x32', 'x48']} noZeroMargin>
      {isHome ? (
        <>
          <BannerLandingLoading />
          <HighlightsLoading />
        </>
      ) : (
        <BannerLoading />
      )}
      <CarouselDisplayLoading />
      <CarouselDisplayLoading isDish />
      <CarouselDisplayLoading />
      <CarouselDisplayLoading />
    </Stack>
  </Box>
)
