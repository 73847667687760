import React, { FC, MouseEventHandler } from 'react'
import styled, { css } from 'styled-components'
import { Stack, Text, theme } from '@lojinha/design-system'
import Link from 'next/link'
import Image, { ImageLoaderProps } from 'next/image'
import { normalizeProductUrl, testId } from '@lojinha/helpers'
import {
  CmsComponentContainerHighlightStory,
  CmsUploadFile,
} from '@lojinha/palantir'

const heightValue = `
  height: 109px;
  ${theme.media.above('md')} {
    height: 140px;
  }
  ${theme.media.below('sm')} {
    height: 72px;
  }
`

const height = () => css`
  ${() => heightValue};
`
const widthValue = `
  min-width: calc((100% - 80px) / 6);
  ${theme.media.below('sm')} {
    min-width: calc((100% - 32px) / 4);
  }
`

const width = () => css`
  ${() => widthValue};
`

const ImageWrapper = styled.div<{
  isHover?: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: ${theme.colors.gray100};
  border-radius: ${theme.space.x8};
  ${height}
  ${width}

  & div {
    position: relative;
    border-radius: ${theme.space.x8};
    ${width}
  }
  & img {
    ${width}
    object-fit: cover;
    transition: transform 300ms ease-out;
    border-radius: ${theme.space.x8};
    &:hover {
      transition: transform 300ms;
      transform: scale(1.1);
    }
    ${({ isHover }) => (isHover ? 'transform: scale(1.1);' : '')}
  }

  > span {
    width: 100% !important;
    height: 100% !important;
  }
`

const PureImage = styled(Image)<{
  isHover?: boolean
}>`
  position: absolute;
  width: 100%;
  object-fit: cover;
  ${height}
`

type Props = {
  url: string
  ariaLabel: string
  isHover?: boolean
}

const imageLoader = ({ src }: ImageLoaderProps) => {
  return `${src}?auto=compress,format`
}

const ItemImage = ({ url, ariaLabel, isHover }: Props) => {
  return (
    <ImageWrapper aria-hidden isHover={isHover}>
      <PureImage
        src={url ?? ''}
        alt={ariaLabel}
        aria-hidden
        loader={imageLoader}
        width="100%"
        height="100%"
      />
    </ImageWrapper>
  )
}

export interface HighlightStoryProps
  extends Omit<CmsComponentContainerHighlightStory, '_id' | 'id' | 'image'> {
  image?: Partial<Pick<CmsUploadFile, 'url'>>
  onClick?: MouseEventHandler<HTMLAnchorElement>
}

const HighlightContainer = styled.a`
  width: calc((100% - 80px) / 6);

  ${theme.media.below('sm')} {
    min-width: calc((100%) / 4);
    max-width: ${theme.space.x96};
  }

  text-decoration: none;
  color: ${theme.colors.black};

  img {
    transition: transform 300ms ease-out;
  }

  &:hover,
  &:active,
  &:focus {
    color: ${theme.colors.success};
    text-decoration: underline;

    img {
      transition: transform 300ms;
      transform: scale(1.1);
    }
  }
`

export const HighlightStory: FC<HighlightStoryProps> = ({
  title,
  destinationUrl,
  image,
  onClick,
}) => (
  <Link href={normalizeProductUrl(destinationUrl)} passHref>
    <HighlightContainer
      onClick={onClick}
      key={`highlight_container_${image}`}
      aria-label={title}
      {...testId('highlight-story')}
    >
      <Stack layout="vertical" margin={['x8', 'x16']}>
        <ItemImage url={image?.url ?? ''} ariaLabel={''} />
        <Text textSize={['x14', 'x2']}>{title}</Text>
      </Stack>
    </HighlightContainer>
  </Link>
)
