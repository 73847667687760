import React, { FC } from 'react'
import {
  Accordion,
  theme,
  Box,
  useWindowProperties,
  Text,
} from '@lojinha/design-system'
import { CmsComponentContainerAccordion } from '@lojinha/palantir'
import { css } from 'styled-components'
import { beholder } from '@lojinha/beholder'
import { useRouter } from 'next/router'
import { CustomText } from '../custom-text'

const descriptionCustomStyle = css`
  font-size: 14px;
`

export const AccordionList: FC<CmsComponentContainerAccordion> = ({
  id,
  title,
  description,
  accordionItem: items,
}) => {
  const { isMobile } = useWindowProperties()
  const router = useRouter()
  const url = router?.asPath

  const emitEvent = (open: boolean, heading: string) =>
    open &&
    beholder.shot('structuredEvent', {
      category: 'navigation',
      action: 'accordion_click',
      label: heading,
      property: url,
    })

  return (
    <>
      <Text as="h2" isBrand textSize={['x2', 'x4']}>
        {title}
      </Text>
      <Box
        htmlAttrs={{
          style: {
            marginTop: theme.space.x8,
            marginBottom: theme.space.x16,
          },
        }}
      >
        <CustomText
          id={id}
          anchorText={description || ''}
          customText={description || ''}
          customStyles={descriptionCustomStyle}
        />
      </Box>
      {items.map((item, index) => (
        <Accordion
          key={`acc-${index}`}
          heading={item.title || ''}
          headingSize={isMobile ? theme.fontSizes.x2 : theme.fontSizes.x20}
          handleChange={open => emitEvent(open, item.title || '')}
          htmlAttrs={{
            style: {
              paddingTop: theme.space.x24,
              paddingBottom: theme.space.x24,
              marginTop: theme.space.x0,
              borderBottom: `1px solid ${theme.colors.gray200}`,
            },
          }}
        >
          <Box paddingTop="x20">
            <CustomText
              id={id}
              customText={item.text || ''}
              anchorText={item.text || ''}
            />
          </Box>
        </Accordion>
      ))}
    </>
  )
}
