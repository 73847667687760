import {
  Box,
  Grid,
  Stack,
  theme,
  useWindowProperties,
} from '@lojinha/design-system'
import React, { FC } from 'react'
import { testId } from '@lojinha/helpers'
import { BannerComponent } from '../../modules/page-content/page-content.types'
import { Breadcrumb } from '../breadcrumb'
import { useBreadCrumbs } from '../breadcrumb/hooks/crumbs.hook'

export const MobileBreadcrumb: FC<{
  component: BannerComponent
  fullPath: string
}> = ({ component, fullPath }) => {
  const crumbs = useBreadCrumbs(`/${fullPath}`)

  const mobileCrumbs = [...crumbs]
  const lastCrumb = mobileCrumbs.pop()

  const { isMobile } = useWindowProperties()

  if (!component.url) return null

  return (
    <>
      {lastCrumb && (
        <Box
          display={['flex', 'flex', 'none']}
          aria-hidden={!isMobile}
          paddingTop="x16"
          htmlAttrs={{
            style: { marginBottom: `-${theme.space.x32}` },
          }}
          {...testId('banner-breadcrumb-test-mobile')}
        >
          <Stack htmlAttrs={{ style: { width: '100%' } }} noZeroMargin>
            <Grid gridTemplateColumns="repeat(24, 1fr)" gridRowGap="x0">
              <Grid gridColumn={['2 / 24', '3 / 12']} display="block">
                <Breadcrumb crumbs={mobileCrumbs} variant="gray" />
              </Grid>
            </Grid>
          </Stack>
        </Box>
      )}
    </>
  )
}
