import { CmsComponentContainerCustomHtml } from '@lojinha/palantir'
import { useEffect, useCallback } from 'react'

type HtmlHookProps = Omit<
  CmsComponentContainerCustomHtml,
  '_id' | 'id' | 'editor' | 'html'
>

export const useCustomHtml = ({ head, css, javascript }: HtmlHookProps) => {
  const createHeadElements = useCallback(() => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(head || '', 'text/html')
    const headElements = doc.head.children
    const elements = []

    for (const element of Object.values(headElements)) {
      const copy = document.createElement(element.tagName)

      for (const attr of Object.values(element.attributes)) {
        copy.setAttribute(attr.name, attr.value)
      }

      elements.push(copy)
      document.head.appendChild(copy)
    }

    return elements
  }, [head])

  const createScriptTag = useCallback(() => {
    const script = document.createElement('script')
    script.setAttribute('defer', 'true')
    script.innerHTML = javascript || ''
    document.head.appendChild(script)

    return script
  }, [javascript])

  const createStyleTag = useCallback(() => {
    const style = document.createElement('style')
    style.setAttribute('scoped', 'true')
    style.innerHTML = css || ''
    document.head.appendChild(style)

    return style
  }, [css])

  useEffect(() => {
    const headElements = createHeadElements()
    const script = createScriptTag()
    const style = createStyleTag()

    return () => {
      script.remove()
      style.remove()
      for (const element of headElements) {
        element.remove()
      }
    }
  }, [createHeadElements, createScriptTag, createStyleTag])
}
