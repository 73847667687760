import styled from 'styled-components'

export const VideoPlayerIframe = styled.iframe(
  ({ theme }) => `
    width: 100%;
    position: relative;
    display: flex;
    overflow: hidden;
    align-items: center;
    border-radius: ${theme.borderRadius.x8}
  `
)

export const VideoWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
`
