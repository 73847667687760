import React, { FC } from 'react'
import { Box, Center, Grid, Text } from '@lojinha/design-system'
import { theme } from '@lojinha/design-system/src/theme'
import { MarketableKind } from '@lojinha/palantir'
import styled from 'styled-components'
import { sortedItemsByAvailability } from '@lojinha/vitrine/src/helpers/products'
import { KitCardList } from '../../components/card-list/kit'
import { MealCardList } from '../../components/card-list/meal'
import { ProductCardList } from '../../components/card-list/product'
import { CardListProps, OriginList } from '../../modules/display/types'
import { normalizeHtmlId } from '@lojinha/helpers'
import { useLojinhaContext } from '@lojinha/context'

const ContainerWithBackgroundGradient = styled(Center)<{
  showGradient?: boolean
}>(
  ({ showGradient }) => `
  background: ${
    showGradient
      ? 'linear-gradient(to bottom,#f6f3ea 0%,rgba(255, 255, 255, 0) min(544px, 100%))'
      : 'none'
  };
  max-width: 100% !important;
  margin-bottom: ${theme.space.x40};
`
)

export const ListDisplay: FC<CardListProps | undefined> = ({
  name,
  items,
  kind,
  originList,
  onRenderItem,
  onItemClick,
  isFirstDisplay,
}: CardListProps) => {
  const { user } = useLojinhaContext()

  if (items.length === 0) return null

  const listByMarketableKind = {
    [MarketableKind.Kit]: (
      <KitCardList
        name={name}
        items={sortedItemsByAvailability(items)}
        originList={OriginList.LIST}
        onItemClick={onItemClick}
        onRenderItem={onRenderItem}
        user={user}
      />
    ),
    [MarketableKind.Meal]: (
      <MealCardList
        name={name}
        items={sortedItemsByAvailability(items)}
        onRenderItem={onRenderItem}
        onItemClick={onItemClick}
        originList={originList}
        user={user}
      />
    ),
    [MarketableKind.Product]: (
      <ProductCardList
        name={name}
        items={sortedItemsByAvailability(items)}
        onRenderItem={onRenderItem}
        onItemClick={onItemClick}
        originList={originList}
        user={user}
      />
    ),
  }
  const anchorId = normalizeHtmlId(name)
  const showGradient = !isFirstDisplay
  return (
    <ContainerWithBackgroundGradient
      htmlAttrs={{ id: anchorId }}
      showGradient={showGradient}
      css={{ marginTop: 0 }}
    >
      <Center>
        <Grid gridTemplateColumns={['repeat(24, 1fr)', 'repeat(12, 1fr)']}>
          <Grid gridColumn={['2 / 24', '2 / 12']}>
            <Box paddingTop={['x40', 'x80']} paddingBottom={['x0', 'x20']}>
              <Text isBrand textTransform="uppercase" textSize="x3">
                {name}
              </Text>
            </Box>
            {listByMarketableKind[kind]}
          </Grid>
        </Grid>
      </Center>
    </ContainerWithBackgroundGradient>
  )
}
