import styled from 'styled-components'

export const ImageWrapper = styled.div`
  width: 100%;
  border-radius: 50%;
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  & img {
    width: 100%;
    object-fit: cover;
    vertical-align: middle;
  }
`

export const PureImage = styled.img`
  position: absolute;
  width: auto;
  object-fit: cover;
`

export const RichText = styled.div<{
  isMobile?: boolean
}>`
  & > p {
    margin-top: ${({ isMobile }) => (isMobile ? '8px' : '16px')};
    padding: ${({ isMobile }) => (isMobile ? '0 16px' : '0')};
    text-align: ${({ isMobile }) => (isMobile ? 'center' : 'inherit')};
    color: inherit;
    font-size: 1rem;
    line-height: 1.5rem;
  }
`
