import React, { FC } from 'react'
import { CmsComponentContainerImage } from '@lojinha/palantir/src/palantirTypes'
import { useWindowProperties } from '@lojinha/design-system'
import { ImageWrapper } from './styles'

interface ImageProps
  extends Omit<CmsComponentContainerImage, '_id' | 'id' | 'image'> {
  image: string
}

export const Image: FC<ImageProps> = ({
  image,
  title,
  isVertical,
  borderRadius: radius,
}) => {
  const { isMobile } = useWindowProperties()

  const borderRadius = radius ? 8 : 0
  const mobileWidth = isMobile && '100%'
  const width = isVertical ? '70%' : '100%'

  return (
    <ImageWrapper style={{ borderRadius, width: mobileWidth || width }}>
      <img
        src={`${image}?auto=compress,format`}
        alt={title ?? ''}
        aria-hidden
        loading="lazy"
        style={{ borderRadius }}
      />
    </ImageWrapper>
  )
}
