import styled from 'styled-components'

export const ImageWrapper = styled.div(
  ({ theme }) => `
    width: 100%;
    position: relative;
    display: flex;
    overflow: hidden;
    align-items: center;
    border-radius: ${theme.space.x8};
    height: 160px;

    ${theme.media.above('md')} {
      height: 280px;
    }
  `
)
