import React, { FC, useEffect } from 'react'
import { ListDisplay } from '../../../components/list-display'
import { DisplayComponent } from '../../page-content/page-content.types'
import { onItemClick, onRenderItem, onRenderList } from '../logic'
import { OriginList } from '../types'

export const CategoryDisplay: FC<DisplayComponent> = ({
  id,
  itemsOptimized: items,
  name,
  slug,
  kind,
  isFirstDisplay,
}) => {
  useEffect(
    () => onRenderList({ id, name, items }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  if (!items.length) return null

  return (
    <ListDisplay
      id={id}
      items={items}
      name={name}
      kind={kind}
      onRenderItem={onRenderItem}
      onItemClick={onItemClick}
      originList={OriginList.CATEGORY}
      slug={slug}
      isFirstDisplay={isFirstDisplay}
    />
  )
}
