import React, { FC } from 'react'
import { Grid, Text, Center, Stack } from '@lojinha/design-system'
import { labels } from '@lojinha/vitrine/src/dictionary/pt-labels'
import styled from 'styled-components'
import { CmsComponentHighlightStoryHighlightStories } from '@lojinha/palantir'
import { beholder } from '@lojinha/beholder'
import { HighlightStory, HighlightStoryProps } from '../highlight-story'

export interface HighlightStoriesProps
  extends Omit<
    CmsComponentHighlightStoryHighlightStories,
    '_id' | 'id' | 'highlights'
  > {
  highlights?: HighlightStoryProps[]
}

const CardsGrid = styled(Grid)(
  ({ theme }) => `
    padding-bottom: ${theme.space.x4};
    margin-top: ${theme.space.x40};

    ${theme.media.below('sm')} {
      margin-top: ${theme.space.x32};
    }
  `
)

export const HighlightStories: FC<HighlightStoriesProps> = ({ highlights }) => {
  if (!highlights?.length) return null

  const highlightsList = highlights?.slice(0, 6)

  const emitEvent = (
    title: string,
    destinationUrl: string,
    position: number
  ) => {
    beholder.shot('structuredEvent', {
      category: 'navigation',
      action: 'highlight-stories',
      label: title,
      property: destinationUrl,
      value: position,
    })
  }

  return (
    <Center>
      <Grid
        htmlAttrs={{ style: { overflowY: 'auto' } }}
        gridTemplateColumns={['repeat(24, 1fr)', 'repeat(12, 1fr)']}
      >
        <Grid gridColumn={['2 / 24', '2 / 12']}>
          <Text
            htmlAttrs={{ style: { position: 'absolute' } }}
            isBold
            textTransform={'uppercase'}
            textSize={'x2'}
            isBrand
          >
            {labels.highlights}
          </Text>
        </Grid>
        <CardsGrid gridColumn={['2 / 24', '2 / 12']}>
          <Stack layout={'horizontal'} alignItems="flex-start">
            {highlightsList?.map((highlight, index) => (
              <HighlightStory
                title={highlight?.title || ''}
                destinationUrl={highlight?.destinationUrl || ''}
                image={highlight?.image}
                onClick={() =>
                  emitEvent(
                    highlight?.title || '',
                    highlight?.destinationUrl || '',
                    ++index
                  )
                }
                key={`highlight_ui_${highlight?.title}`}
              />
            ))}
          </Stack>
        </CardsGrid>
      </Grid>
    </Center>
  )
}
