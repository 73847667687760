import React from 'react'
import { Box, Anchor, Text } from '@lojinha/design-system'
import { theme } from '@lojinha/design-system/src/theme'
import { testId } from '@lojinha/helpers'
import { CrumbProps } from '../types'

const breadcrumbColors = {
  dark: {
    primary: 'black',
    secondary: 'gray700',
  },
  light: {
    primary: 'white',
    secondary: 'gray300',
  },
  gray: {
    primary: 'gray700',
    secondary: 'gray300',
  },
} as const

export const Crumb = ({
  label,
  route,
  ariaLabel,
  isCurrent = false,
  onCrumbClick,
  variant = 'dark',
}: CrumbProps) => {
  return (
    <Box
      as="li"
      key={route}
      display="flex"
      alignItems="center"
      minHeight="48px"
    >
      {isCurrent || !route ? (
        <Text
          isBold
          textTransform="uppercase"
          textSize="x1"
          color={breadcrumbColors[variant].secondary}
          htmlAttrs={{
            'aria-label': `Página atual: ${label}`,
            'aria-current': 'page',
            style: {
              cursor: 'not-allowed',
              whiteSpace: 'nowrap',
            },
          }}
          {...testId(`crumb${route?.replace(/\//g, '-')}`)}
        >
          {label}
        </Text>
      ) : (
        <Anchor
          isBold
          isUppercased
          textSize="x1"
          color={breadcrumbColors[variant].primary}
          onClick={() => onCrumbClick && onCrumbClick(route)}
          htmlAttrs={{
            'aria-label': ariaLabel,
            style: {
              whiteSpace: 'nowrap',
            },
            tabIndex: 0,
          }}
          {...testId(`crumb${route.replace(/\//g, '-')}`)}
        >
          {label}
        </Anchor>
      )}
      {!isCurrent && (
        <Text
          as="span"
          color={breadcrumbColors[variant].primary}
          isBold
          textSize="x1"
          htmlAttrs={{
            'aria-hidden': true,
            style: { paddingLeft: theme.space.x4 },
          }}
        >
          /
        </Text>
      )}
    </Box>
  )
}
