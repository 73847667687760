import React from 'react'
import styled from 'styled-components'
import { Box, Center, Grid, Skeleton } from '@lojinha/design-system'
import { testId } from '@lojinha/helpers'

const LoadingBanner = styled(Center)(
  ({ theme }) => `
    position: relative;
    width: 100%;
    height: 160px;
    
    ${theme.media.above('md')} {
      height: 280px;
    }
  `
)

const LoadingGrid = styled(Grid)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
`

const LoadingGridColumn = styled(Grid)(
  ({ theme }) => `
    border-radius: ${theme.space.x8};
    overflow: hidden;
  `
)

export const BannerLandingLoading = () => {
  return (
    <Box
      paddingBottom="x40"
      paddingTop="x64"
      {...testId('banner-landing-loading')}
    >
      <LoadingBanner>
        <LoadingGrid
          gridTemplateColumns={['repeat(24, 1fr)', 'repeat(12, 1fr)']}
        >
          <LoadingGridColumn gridColumn={['2/24', '2 / 12']}>
            <Skeleton />
          </LoadingGridColumn>
        </LoadingGrid>
      </LoadingBanner>
    </Box>
  )
}
