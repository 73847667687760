import React, { FC } from 'react'
import { useLojinhaContext } from '@lojinha/context'
import { beholder } from '@lojinha/beholder/src'
import { useWindowProperties } from '@lojinha/design-system'
import { Link } from '@lojinha/vitrine/src/components/link'
import { normalizeProductUrl } from '@lojinha/helpers'
import { CmsComponentContainerBanner } from '@lojinha/palantir'
import Image, { ImageLoaderProps } from 'next/image'
import { ImageWrapper } from './styles'

interface BannerProps
  extends Omit<
    CmsComponentContainerBanner,
    'id' | 'imageMobile' | 'imageDesktop'
  > {
  imageMobile: string
  imageDesktop: string
}

const myImageLoader = ({ src }: ImageLoaderProps) => {
  return `${src}?auto=compress,format`
}

export const Banner: FC<BannerProps> = ({
  imageMobile,
  imageDesktop,
  title,
  redirectUrl,
  description,
}) => {
  const { isMobile } = useWindowProperties()

  const { isMobileUserAgent } = useLojinhaContext()

  const isMobileScreen = isMobile || isMobileUserAgent

  const onClick = () => {
    const imageUrl = isMobile || isMobileUserAgent ? imageMobile : imageDesktop
    const imageUrlSplited = imageUrl?.split('/') ?? ''
    const trackerLabel = imageUrlSplited[imageUrlSplited.length - 1]

    beholder.shot('structuredEvent', {
      category: 'navigation',
      action: 'click_home_banner',
      label: trackerLabel,
      property: redirectUrl ?? '',
      value: null,
    })
  }

  return (
    <Link
      href={normalizeProductUrl(redirectUrl || '/')}
      htmlAttrs={{ 'aria-label': description || title }}
    >
      {isMobileScreen && (
        <ImageWrapper onClick={onClick}>
          <Image
            src={imageMobile}
            alt={title ?? ''}
            aria-hidden
            priority
            layout="fill"
            loader={myImageLoader}
            //@ts-ignore
            fetchpriority="high"
          />
        </ImageWrapper>
      )}
      {!isMobileScreen && (
        <ImageWrapper onClick={onClick}>
          <Image
            src={imageDesktop}
            alt={title ?? ''}
            aria-hidden
            priority
            layout="fill"
            loader={myImageLoader}
            //@ts-ignore
            fetchpriority="high"
          />
        </ImageWrapper>
      )}
    </Link>
  )
}