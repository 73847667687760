import React from 'react'

import { Box, Stack } from '@lojinha/design-system'
import {
  CmsContainer,
  ContainerComponentsDynamicZoneUnion,
} from '@lojinha/palantir/src/palantirTypes'
import { CMSComponents } from '../../cmsComponents'
import { CMSMainContainer } from '../container'
import { IAnchorControl } from '../navigation-anchors/types'

type ComponentWithTypename = ContainerComponentsDynamicZoneUnion &
  Required<{ __typename: string }>

function cmsComponentsMap(
  container: CmsContainer,
  component: ComponentWithTypename,
  anchorControl: IAnchorControl,
  index: number
) {
  const containerFn = CMSComponents[component.__typename]
  const containerFiltered = containerFn(component, container, anchorControl)
  const key = `container-${index}-${component.id}`

  if (index === 0 && component.__typename === 'CMSComponentContainerBanner') {
    return (
      <Box key={key} paddingTop={['x16', 'x64']} width={'100%'}>
        {containerFiltered}
      </Box>
    )
  }
  return <React.Fragment key={key}>{containerFiltered}</React.Fragment>
}

export const CMSPageContent: React.FC<{
  container: CmsContainer
  anchoredComponents: IAnchorControl
}> = ({ container, anchoredComponents }) => {
  const containerComponents = (container.components || [])
    .filter(component => component.__typename)
    .map(component => component as ComponentWithTypename)

  const isAnchorContainer = containerComponents.some(
    component =>
      component.__typename === 'CMSComponentContainerNavigationAnchors'
  )

  const jsxElements = containerComponents.map((component, index) =>
    cmsComponentsMap(container, component, anchoredComponents, index)
  )

  if (isAnchorContainer) {
    return <>{jsxElements}</>
  }
  return (
    <Stack margin={['x48', 'x80']} key={`container-${container.id}`}>
      <CMSMainContainer {...container}>{jsxElements}</CMSMainContainer>
    </Stack>
  )
}
