import { useWindowProperties } from '@lojinha/design-system'
import { useRouter } from 'next/router'
import { RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { normalizeHtmlId } from '@lojinha/helpers'

interface IUseAnchorControlProps {
  displayNames: string[]
  skip?: boolean
}

export interface IAnchorControl {
  ref: RefObject<HTMLDivElement>
  active: string
  setActive: (anchorId: string) => void
}

export function useAnchorControl({
  displayNames,
  skip,
}: IUseAnchorControlProps): IAnchorControl {
  const { isMobile } = useWindowProperties()
  const { asPath } = useRouter()
  const click = useRef('')
  const ref = useRef<HTMLDivElement>(null)

  const [activeAnchor, setActiveAnchor] = useState(
    normalizeHtmlId(displayNames[0] || '')
  )

  const changeUrlAnchor = useCallback(
    (anchorId: string) => {
      anchorId &&
        window.history.replaceState(
          window.history.state,
          '',
          `${asPath.split('#')[0]}#${anchorId}`
        )
    },
    [asPath]
  )

  const getAnchorTop = (anchorId: string) => {
    const anchorEl = ref.current?.querySelector(`#${anchorId}`)
    return anchorEl?.getBoundingClientRect().top ?? 0
  }

  const scrollTo = useCallback(
    (anchorId: string) => {
      const anchorTop = getAnchorTop(anchorId)
      const newAnchorTop = isMobile
        ? anchorTop + window.scrollY - 120
        : anchorTop + window.scrollY - 160

      window.scrollTo({
        top: newAnchorTop,
        behavior: 'smooth',
      })
    },
    [isMobile]
  )

  const setActive = useCallback(
    (anchorId: string) => {
      setActiveAnchor(anchorId)
      click.current = anchorId
      scrollTo(anchorId)
      changeUrlAnchor(anchorId)
    },
    [changeUrlAnchor, scrollTo]
  )

  const getAnchorSelectedByUserScroll = useCallback((names: string[]) => {
    const anchors = names.map(name => {
      const normalizedName = normalizeHtmlId(name)
      const anchorTop = getAnchorTop(normalizedName)

      return {
        name: name,
        top: anchorTop,
      }
    })

    const selectedAnchor = [...anchors].reverse().find(_anchor => {
      return _anchor.top < 200
    })

    const selectedAnchorName = selectedAnchor?.name ?? names[0]
    return normalizeHtmlId(selectedAnchorName)
  }, [])

  useEffect(() => {
    if (skip) return

    const anchorIdSplited = asPath.split('#')
    const anchorIdAsPath = normalizeHtmlId(
      (anchorIdSplited.length && anchorIdSplited[1]) || ''
    )

    if (anchorIdAsPath) {
      setActive(anchorIdAsPath)
    }
  }, [asPath, setActive, skip])

  const handleScroll = useCallback(() => {
    const newActive = getAnchorSelectedByUserScroll(displayNames)

    const isUserScroll = !click.current
    if (isUserScroll) {
      setActiveAnchor(newActive)
      changeUrlAnchor(newActive)
    } else if (click.current === newActive) {
      setActiveAnchor(newActive)
      changeUrlAnchor(newActive)
      click.current = ''
    }
  }, [changeUrlAnchor, displayNames, getAnchorSelectedByUserScroll])

  useEffect(() => {
    if (skip) return

    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll, skip])

  return {
    ref,
    active: activeAnchor,
    setActive,
  }
}
