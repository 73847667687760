import React, { FC } from 'react'
import {
  Box,
  Button,
  Text,
  Grid,
  useWindowProperties,
} from '@lojinha/design-system'
import { CmsComponentContainerCallToAction, CmsColor } from '@lojinha/palantir'
import { beholder } from '@lojinha/beholder'
import { ImageWrapper, PureImage, RichText } from './styles'

interface CallToActionProps
  extends Omit<CmsComponentContainerCallToAction, '_id' | 'id' | 'color'> {
  color?: Omit<CmsColor, 'id'> | null
}

export const CallToAction: FC<CallToActionProps> = ({
  title,
  text,
  image,
  link,
  buttonText,
  color,
}) => {
  const { isMobile } = useWindowProperties()

  const emitEvent = (btnText: string, destinationUrl: string) =>
    beholder.shot('structuredEvent', {
      category: 'navigation',
      action: 'cms_button_click',
      label: btnText,
      property: destinationUrl,
    })

  return (
    <Grid
      gridTemplateColumns={'repeat(12, 1fr)'}
      gridTemplateRows={'repeat(1, 1fr)'}
      gridRowGap={'x0'}
      htmlAttrs={{
        style: {
          backgroundColor: `${color?.background}`,
          padding: `${isMobile ? '48px 0' : '80px 0'}`,
        },
      }}
    >
      <Grid gridColumn={['3/11', '3/7']}>
        <ImageWrapper>
          <PureImage
            src={image?.url}
            alt={title ?? ''}
            aria-hidden
            loading="lazy"
          />
          <img src={image?.url} alt={title ?? ''} aria-hidden loading="lazy" />
        </ImageWrapper>
      </Grid>
      <Grid gridColumn={['1/13', '8/11']}>
        <Box
          htmlAttrs={{
            style: {
              margin: `${isMobile ? '0' : 'auto 0'}`,
              textAlign: `${isMobile ? 'center' : 'inherit'}`,
            },
          }}
        >
          <Text
            isBrand
            as="h2"
            textSize={['x3', 'x4']}
            textTransform="uppercase"
            htmlAttrs={{
              style: {
                marginTop: `${isMobile ? '24px' : '16px'}`,
                padding: `${isMobile ? '0 16px' : '0'}`,
                textAlign: `${isMobile ? 'center' : 'inherit'}`,
              },
            }}
          >
            {title}
          </Text>
          <RichText
            isMobile={isMobile}
            dangerouslySetInnerHTML={{
              __html: text || '',
            }}
          />
          {link && (
            <Box
              htmlAttrs={{
                style: { marginTop: '24px' },
              }}
            >
              <a href={link} rel="noreferrer">
                <Button
                  onClick={() => emitEvent(buttonText || '', link)}
                  size="large"
                >
                  {buttonText}
                </Button>
              </a>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}
